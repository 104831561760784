import { _ } from '../../../../util/translate';
import * as Yup from 'yup';

export const validationSchemaChatSettings = (
  messengerMaxPrice: number,
  messengerMinPrice: number,
  singleChatMaxPrice: number,
  singleChatMinPrice: number,
  videoChatMaxPrice: number,
  videoChatMinPrice: number
) =>
  Yup.object().shape({
    settings: Yup.object().shape({
      conversionChat: Yup.object().shape({
        status: Yup.bool(),
      }),
      freeChat: Yup.object().shape({
        status: Yup.bool(),
      }),
      livePreview: Yup.object().shape({
        status: Yup.bool(),
      }),
      partyChat: Yup.object().shape({
        status: Yup.bool(),
      }),
      softChat: Yup.object().shape({
        status: Yup.bool(),
      }),
      voyeurInSingleChat: Yup.object().shape({
        status: Yup.bool(),
      }),
      soundForVoyeurInSingleChat: Yup.object().shape({
        status: Yup.bool(),
      }),
    }),
    sliders: Yup.object().shape({
      messengerDefaultPrice: Yup.number()
        .min(
          messengerMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [messengerMinPrice] })
        )
        .max(
          messengerMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [messengerMaxPrice] })
        ),
      messengerUserPrice: Yup.number()
        .min(
          messengerMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [messengerMinPrice] })
        )
        .max(
          messengerMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [messengerMaxPrice] })
        ),
      singleChatDefaultPrice: Yup.number()
        .min(
          singleChatMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [singleChatMinPrice] })
        )
        .max(
          singleChatMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [singleChatMaxPrice] })
        ),
      singleChatUserPrice: Yup.number()
        .min(
          singleChatMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [singleChatMinPrice] })
        )
        .max(
          singleChatMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [singleChatMaxPrice] })
        ),
      videoChatDefaultPrice: Yup.number()
        .min(
          videoChatMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [videoChatMinPrice] })
        )
        .max(
          videoChatMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [videoChatMaxPrice] })
        ),
      videoChatUserPrice: Yup.number()
        .min(
          videoChatMinPrice,
          _('common:formValidationMessage.minimum', { sprintf: [videoChatMinPrice] })
        )
        .max(
          videoChatMaxPrice,
          _('common:formValidationMessage.maximum', { sprintf: [videoChatMaxPrice] })
        ),
    }),
  });
