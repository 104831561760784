import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { _ } from '../../../../util/translate';
import Markdown from '../../../../components/Markdown/Markdown';
import { T } from '../../../../components';

import { Heading } from '../styles';
import LinkBox from '../../../../components/LinkBox/LinkBox';
import Spinner from '../../../../components/Spinner/Spinner';
import OnOffStatus from '../../../../components/OnOffStatus/OnOffStatus';
import ProfilePictureInfobox from '../../../../components/ProfilePictureInfobox/ProfilePictureInfobox';
import { VXCashActionCreators } from '../../../../stores/VXCash/VXCashActionCreators';
import { getFormattedDate } from '../../../../util/Formatter';
import { Link } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../util/env';
import { withAppState } from '../../../../util/AppState';
import { Section } from '../../../../atoms';
import { Box, Typography } from '@material-ui/core';
import { BLUE } from '../../../../camtool-styles';

class Instagram extends PureComponent {
  handleOnOffStatusChange = (event) => {
    if (event.target.value === 'on') {
      VXCashActionCreators.requestInstagramAdlink();
    }
  };

  renderLinkBox = () => {
    if (status === null) return;

    switch (this.props.status) {
      case 'notRequested':
        return (
          <div css={{ display: 'flex', flexDirection: 'column ' }}>
            <div css={{ alignItems: 'center', marginRight: 16 }}>
              <T _={'vxcash:inviteGuest.insta.activateLabel'} />
            </div>
            <OnOffStatus
              status="off"
              name="inta"
              labelOn={_('common:onOff.enable')}
              labelOnActive={_('common:onOff.enabled')}
              labelOff={_('common:onOff.disable')}
              labelOffActive={_('common:onOff.disabled')}
              onChange={this.handleOnOffStatusChange}
            />
          </div>
        );

      case 'rejected':
        // eslint-disable-next-line react/prop-types
        const [{ lang }] = this.props.appState;

        return (
          <ProfilePictureInfobox
            iconSize={1}
            text={`${_('vxcash:inviteGuest.insta.rejectedMsg1')} ${
              // eslint-disable-next-line react/prop-types
              this.props.rejectedUntil
                ? _('vxcash:inviteGuest.insta.rejectedMsg2', {
                    postProcess: 'sprintf',
                    // eslint-disable-next-line react/prop-types
                    sprintf: [getFormattedDate(this.props.rejectedUntil, lang)],
                  })
                : ''
            }`}
            css={{
              color: '#ffb033',
              backgroundColor: 'transparent',
              padding: '8px 0',
              fontSize: 14,
            }}
          />
        );

      case 'new':
        return (
          <ProfilePictureInfobox
            iconSize={1}
            text={_('vxcash:inviteGuest.insta.pendingMsg')}
            css={{
              color: '#ffb033',
              backgroundColor: 'transparent',
              padding: '8px 0',
              fontSize: 14,
            }}
          />
        );

      case 'accepted':
        return (
          <LinkBox
            iconColor="#fff"
            iconBgColor="#bd3381"
            iconText="IP"
            link={this.props.link}
            label={_('vxcash:inviteGuest.insta.linkBoxLabel')}
            buttonLabelCopy={_('common:text.linkCopy')}
            buttonLabelCopied={_('common:text.linkCopied')}
          />
        );

      case 'noInstaAccount':
        return (
          <div>
            <p>
              <T _={'vxcash:inviteGuest.insta.addAccountInfo'} />{' '}
              <Link css={{ color: BLUE }} to={`${APP_BASE_PATH}/account/personaldata#instaAccount`}>
                <T _={'vxcash:inviteGuest.insta.addAccountLink'} />
              </Link>
            </p>
          </div>
        );
    }
  };

  render() {
    console.log('data', this.props);
    return (
      <Section title={_('vxcash:inviteGuest.insta.heading0')}>
        <div className="grid__box__row">
          <div className="grid__box__column grid__box__sub spinner-container">
            {this.props.dataLoading && <Spinner />}
            <Box css={{ flexDirection: 'row', padding: '15px' }}>
              <Box css={{ margin: '0 0 10px 0' }}>
                <Box>
                  <Typography variant="h3" component="h3">
                    <T _={'vxcash:inviteGuest.insta.headingPromoDescription'} />
                  </Typography>
                </Box>
                <Box>
                  <Markdown>{_('vxcash:inviteGuest.insta.textPromoDescription')}</Markdown>
                </Box>
              </Box>

              {/* 2 */}
              <Box css={{ margin: '0 0 10px 0' }}>
                <Box>
                  <Typography variant="h3" component="h3">
                    <T _={'vxcash:inviteGuest.insta.headingPromoRequirements'} />
                  </Typography>
                </Box>
                <Box>
                  <Markdown>{_('vxcash:inviteGuest.insta.textPromoRequirements')}</Markdown>
                </Box>
                <Box>
                  {this.props.link && (
                    <LinkBox
                      iconColor="#fff"
                      iconBgColor="#ff4d3c"
                      iconText="VX"
                      link={this.props.link}
                      buttonLabelCopy={_('common:text.linkCopy')}
                      buttonLabelCopied={_('common:text.linkCopied')}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </Section>
    );
  }
}

Instagram.propTypes = {
  link: PropTypes.string,
  dataLoading: PropTypes.bool,
  status: PropTypes.oneOf(['notRequested', 'new', 'accepted', 'rejected', 'noInstaAccount', null]),
};

Instagram.defaultProps = {
  link: '',
  dataLoading: false,
  status: null,
  rejectedUntil: null,
};

export default withAppState(Instagram);
