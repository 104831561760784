import React from 'react';

import { getFormattedDuration } from '../../../util/Formatter';

import DefaultWidget from './DefaultWidget';
import { _ } from '../../../util/translate';

const OnlineTimeThisMonth = React.memo(({ loading, className, monthOnlineTime }) => (
  <DefaultWidget
    loading={loading}
    className={className}
    iconName="cup"
    iconColor="#ffb033"
    label={_('dashboard:app.onlineTimeThisMonth.header')}
    data={getFormattedDuration(!!monthOnlineTime ? monthOnlineTime : 0)}
  />
));

export default OnlineTimeThisMonth;
