import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { downloadAlt } from '../../../../../atoms/Icon/libraries/glyph';

const SoftwareItem: FC = () => (
  <LeftMenuItem title={_('navigation:videochat.software')} icon={downloadAlt} uri="/get-online" />
);

export default SoftwareItem;
