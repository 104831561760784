import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GraphIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox={'0 0 13 12.5'}>
    <path d="M11.43,2.46,11,3.12V3.5h1.5V4H11V5.5h1.5V6H11V7.5h1.5V8H11V9.5h1.5V10H11v1.5h1.5v1H0V0H1V1.5H2.5V0H3V1.5H4.5V0H5V1.5H6.5V0H7V1.5H8.5V0H9V1.5h1.5V0H11V.26A1.19,1.19,0,0,1,11.75,0a1.24,1.24,0,0,1,.63.17,1.21,1.21,0,0,1,.45.45,1.24,1.24,0,0,1,.17.63,1.16,1.16,0,0,1-.37.88,1.16,1.16,0,0,1-.88.37A1,1,0,0,1,11.43,2.46ZM2.5,3.5V2H1V3.5Zm0,2V4H1V5.5Zm0,2V6H1V7.5Zm0,1.7V8H1V9.5H2.23ZM1,10v.92L1.8,10Zm1.5,1.5V10.34l-1,1.16Zm2-8V2H3V3.5Zm0,2V4H3V5.5Zm0,1V6H3V7.5H3a1.27,1.27,0,0,1,.44-.71,1.19,1.19,0,0,1,.79-.28A1.07,1.07,0,0,1,4.5,6.54ZM3,8v.62l.19-.21A1.12,1.12,0,0,1,3,8Zm1.5,3.5V10H3v1.5Zm0-2V9L4.25,9a1.44,1.44,0,0,1-.5-.1l-.52.59ZM5,2V3.5H6.5V2ZM5,4V5.5H6.5V4ZM5,6v.76L5.19,7,7.32,6H7v.14l-.5.22V6ZM6.5,9.5V8h-1A1.2,1.2,0,0,1,5,8.76V9.5Zm0,2V10H5v1.5Zm0-4V7.18l-.71.32Zm2-4V2H7V3.5Zm0,1V4H7V5.5h.52a2.31,2.31,0,0,1,.1-.28A1,1,0,0,1,7.77,5,1.34,1.34,0,0,1,8,4.78a1,1,0,0,1,.24-.15A.92.92,0,0,1,8.5,4.54Zm0,3V7a1.23,1.23,0,0,1-.67-.4L7,7V7.5Zm0,2V8H7V9.5Zm0,2V10H7v1.5Zm2-9V2H9V3.5h.85ZM9,4v.54l.13,0L9.52,4Zm1.5,3.5V6H10a1.21,1.21,0,0,1-.34.64A1.3,1.3,0,0,1,9,7V7.5Zm0,2V8H9V9.5Zm0,2V10H9v1.5Zm0-6V4h-.08L9.75,5A1.28,1.28,0,0,1,10,5.5Z" />
  </SvgIcon>
);

export default GraphIcon;
