import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';
import InviteUser from './InviteUsers/InviteUsers';
import InviteModel from './InviteModels/InviteModels';
import Statistics from './Statistics/Statistics';
import GoToVXCash from './GoToVXCash/GoToVXCash';
import Register from './Register/Register';
import { useUserData } from '../../util/UserData';

// eslint-disable-next-line react/prop-types
const VXCash = ({ match: { url } }) => {
  const path = stripSlash(url);
  const {
    model: {
      vxcash: { wmid },
    },
  } = useUserData();

  const hasWMID = () => wmid > 0;
  const hasNoWMID = () => !wmid || wmid === 0;
  const indexPath = `${path}/${hasWMID() ? 'invite-users' : 'register'}`;

  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={indexPath} />
      <ProtectedRoute
        path={`${path}/invite-users`}
        component={InviteUser}
        redirectTo={`${path}/register`}
        verify={hasWMID}
      />
      <ProtectedRoute
        path={`${path}/invite-models`}
        component={InviteModel}
        redirectTo={`${path}/register`}
        verify={hasWMID}
      />
      <ProtectedRoute
        path={`${path}/statistics`}
        component={Statistics}
        redirectTo={`${path}/register`}
        verify={hasWMID}
      />
      <ProtectedRoute
        path={`${path}/gotovxcash`}
        component={GoToVXCash}
        redirectTo={`${path}/register`}
        verify={hasWMID}
      />
      <ProtectedRoute
        path={`${path}/register`}
        component={Register}
        verify={hasNoWMID}
        redirectTo={`${path}/`}
      />
      <Redirect to={`${path}/admails`} />
    </Switch>
  );
};
export default VXCash;
