import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import AbstractProfile from '../../AbstractProfile';
import { PROFILE_FL } from '../../../../stores/Profile/Profile';

import NavSubFL from '../NavSubFL';
import { Button, ButtonGroup, Checkbox, Form, GroupRow, Spinner, T } from '../../../../components';

import { _ } from '../../../../util/translate';

import pic1 from './img/preview-grafik-1.jpg';
import pic2 from './img/preview-grafik-2.jpg';
import pic3 from './img/preview-grafik-3.jpg';
import pic4 from './img/preview-grafik-4.jpg';
import pic5 from './img/preview-grafik-5.jpg';
import pic6 from './img/preview-grafik-6.jpg';
import picLaptop from './img/flirtlife-laptop.png';
import picHeader from './img/header.png';

const Row = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 48px 0;
  background-color: #fff;
  ${(props) => (props.styles ? css(props.styles) : null)};
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: bold;
  margin: 0 0 16px 0;
`;

class Settings extends AbstractProfile {
  getResource() {
    return PROFILE_FL;
  }

  getInputNames() {
    return ['flSettingsProfileActive', 'flSettingsAutomessagingActive'];
  }

  render() {
    super.render();
    return (
      <main id="la-profile-settings" className="la-profile-settings grid">
        {this.storeValues.flSettingsProfileActive && <NavSubFL />}

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <div className="grid__box__item">
              <div className="grid__box__item__content" css={{ flexDirection: 'column' }}>
                <div
                  css={{
                    flexDirection: 'column',
                    backgroundImage: `url(${picHeader})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <Row
                    styles={{
                      color: 'white',
                      backgroundColor: 'rgba(0, 0, 0, .6)',
                      fontSize: '1.35em',
                    }}
                  >
                    <Title css={{ margin: '0 0 16px 0', filter: 'drop-shadow(0 0 4px #000)' }}>
                      Du möchtest mit Flirt-Nachrichten Geld verdienen?
                    </Title>
                    <p css={{ maxWidth: 710, padding: '0 32px' }}>
                      Dann erstelle ein Profil auf unserer Dating Plattform Flirtlife, flirte und
                      habe Spaß mit echten Männern im Textchat, verdiene dabei Geld mit soften
                      Nachrichten und steigere somit Deinen Umsatz!
                    </p>
                  </Row>
                </div>

                <Row>
                  <Title>Was Du beachten solltest</Title>
                  <p css={{ maxWidth: 700, padding: '0 32px' }}>
                    Der Clou: Du musst Dich nicht extra anmelden. Das Profil und das Postfach
                    bearbeitest Du über VXModels. Flirte und habe Spaß mit echten Männern im
                    Textchat, verdiene dabei Geld mit soften Nachrichten und steigere somit Deinen
                    Umsatz! Nachdem das Profil vollständig ausgefüllt und durch unseren Support
                    geprüft wurde, wird es auf Flirtlife angezeigt.
                  </p>

                  <div css={{ marginTop: 56 }}>
                    <div
                      css={{
                        flexDirection: 'column',
                        textAlign: 'left',
                        color: 'white',
                        backgroundColor: '#1f93e9',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 232,
                        height: 232,
                        borderRadius: '50%',
                        padding: 28,
                        position: 'relative',
                        left: 64,
                        '@media (max-width: 1172px)': { left: -16 },
                      }}
                    >
                      <div css={{ marginBottom: 8, fontSize: '1.8em', fontWeight: 'bold' }}>
                        <span className="icon-thumbs-up" />
                        <span css={{ marginLeft: 4 }}>Do´s</span>
                      </div>
                      <ul css={{ paddingLeft: 24, li: { lineHeight: '1.5em' } }}>
                        <li>sympathische, Flirttexte</li>
                        <li>zeige wer Du bist</li>
                        <li>Profilbilder des alltäglichen Lebens</li>
                        <li>jugendfreie Fotos (FSK 12)</li>
                      </ul>
                    </div>

                    <div
                      css={{
                        position: 'relative',
                        zIndex: 2,
                        minWidth: 453,
                        '@media (max-width: 1172px)': { display: 'none ' },
                      }}
                    >
                      <img src={picLaptop} />
                    </div>

                    <div
                      css={{
                        flexDirection: 'column',
                        textAlign: 'left',
                        color: 'white',
                        backgroundColor: '#1f93e9',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 232,
                        height: 232,
                        borderRadius: '50%',
                        padding: 28,
                        position: 'relative',
                        right: 64,
                        '@media (max-width: 1172px)': { right: -16 },
                      }}
                    >
                      <div css={{ marginBottom: 8, fontSize: '1.8em', fontWeight: 'bold' }}>
                        <span className="icon-thumbs-down" />
                        <span css={{ marginLeft: 4 }}>Dont´s</span>
                      </div>
                      <ul css={{ paddingLeft: 24, li: { lineHeight: '1.5em' } }}>
                        <li>keinerlei sexuelle Inhalte</li>
                        <li>keine Camfunktion anpreisen</li>
                        <li>User sollen nicht abgeworben werden</li>
                        <li>professionelle Fotos</li>
                      </ul>
                    </div>
                  </div>
                </Row>

                <Row styles={{ paddingBottom: 96, paddingTop: 0 }}>
                  <Title css={{ margin: '0 0 16px 0' }}>Das ideale Profilbild</Title>
                  <p css={{ maxWidth: 680, padding: '0 32px' }}>
                    Erlaubt sind hier ausschließlich jugendfreie (FSK 12) Fotos, die keine
                    Schriftzüge haben und keinen Bezug zu VISIT-X herstellen. Lade hier nur Fotos
                    hoch, die einen privaten Eindruck von Dir vermitteln und zu einer Dating-Seite
                    passen. Professionelle Fotos aus einem Fotoshooting sind ungeeignet.
                  </p>

                  <div css={{ justifyContent: 'center', flexWrap: 'wrap', margin: '0 32px' }}>
                    <div css={{ paddingTop: 48, height: 256 }}>
                      <img
                        src={pic1}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'rotate(-10deg)',
                        }}
                      />
                      <img
                        src={pic2}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'translateY(48px) rotate(4deg)',
                        }}
                      />
                      <img
                        src={pic3}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'rotate(-2deg)',
                        }}
                      />
                    </div>

                    <div
                      css={{
                        paddingTop: 48,
                        height: 256,
                        '@media (max-width: 1172px)': { display: 'none' },
                      }}
                    >
                      <img
                        src={pic4}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'rotate(15deg)',
                        }}
                      />
                      <img
                        src={pic6}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'rotate(-3deg)',
                        }}
                      />
                      <img
                        src={pic5}
                        alt="Beispiel bild für FlirtLife"
                        css={{
                          width: 'auto',
                          height: 128,
                          borderTop: '16px solid white',
                          borderLeft: '8px solid white',
                          borderRight: '8px solid white',
                          borderBottom: '32px solid white',
                          boxShadow: '0 0 8px 1px rgba(0,0,0,.5)',
                          transform: 'translateY(48px) rotate(4deg)',
                        }}
                      />
                    </div>
                  </div>
                </Row>

                <Row styles={{ borderTop: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                  <Form
                    className="grid__box__item spinner-container"
                    // eslint-disable-next-line react/no-string-refs
                    ref="form"
                    onValidSubmit={this.submitForm}
                    onInvalidSubmit={this.showErrorFields}
                    onValid={this.onValid}
                    onInvalid={this.onInvalid}
                    onChange={this.onChange}
                  >
                    {this.state.isLoading && <Spinner size="m" />}

                    <div className="grid__box__item__content la-profile__formwidth">
                      <GroupRow>
                        <Checkbox
                          className={css({ padding: 0 })}
                          name="flSettingsProfileActive"
                          label={_('profiles:fl.settings.formfield.profileActive.label')}
                          value={this.storeValues.flSettingsProfileActive}
                        />
                      </GroupRow>

                      <GroupRow>
                        <Checkbox
                          className={css({ padding: 0 })}
                          name="flSettingsAutomessagingActive"
                          label={_('profiles:fl.settings.formfield.automessagingActive.label')}
                          value={this.storeValues.flSettingsAutomessagingActive}
                        />
                      </GroupRow>

                      <ButtonGroup position="center" css={{ padding: 4 }}>
                        {/*
                        <Button
                          label={_('common:button.cancel')}
                          onClick={this.rejectChanges}
                          type="button"
                          disabled={!this.activateResetButton}
                        />
                        */}

                        <Button
                          className="button--blue"
                          label={_('common:button.save')}
                          type="submit"
                        />
                      </ButtonGroup>
                    </div>
                  </Form>
                </Row>
              </div>
            </div>
          </section>
        </section>
      </main>
    );
  }
}

export default Settings;
