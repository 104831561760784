import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { money } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';

const FinancesItem: FC = () => (
  <LeftMenuItem title={_('navigation:main.finances')} icon={money} uri="/finances" />
);

export default FinancesItem;
