import styled from '@emotion/styled';
import Icon from '../../../../atoms/Icon/Icon';
import { BLACK_1, GRAY_2 } from '../../../../camtool-styles';

export const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  color: ${BLACK_1};
  margin-bottom: 11px;
`;

export const Title = styled.h3`
  font-size: 18px;
  max-width: 236px;
  text-align: center;
  margin-bottom: 16px;
  color: ${BLACK_1};
`;

export const Description = styled.p`
  max-width: 205px;
  text-align: center;
  color: ${GRAY_2};
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 25px;
`;
