import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { user } from '../../../../../atoms/Icon/libraries/glyph';

const InviteUsersItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:affiliate.inviteUsers')}
    icon={user}
    uri="/vxcash/invite-users"
  />
);

export default InviteUsersItem;
