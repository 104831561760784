import React from 'react';
import { SessionStore } from '../../stores/Session/Session';

import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../util/env';
import {hasRole} from "../../util/UserData";
import {SecurityRole} from "../../graphql/VXModels/types";
import { _ } from '../../util/translate';

class NavSubFinances extends React.Component {
  constructor(props) {
    super(props);
    this.session = SessionStore.get();
  }

  render() {
    const hasRoleVxUserMaster = hasRole(SecurityRole.VX_USER_MASTER);

    return (
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/finances/overview`}
          icon="icon-charts"
          label={_('finances:overview.header')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/finances/payout`}
          icon="icon-money"
          label={_('finances:payout.header')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/finances/invoice`}
          icon="icon-invoice"
          label={_('finances:billing.header')}
        />
        {/*
        {hasRoleVxUserMaster && <NavSubItem
          link={`${APP_BASE_PATH}/account/payoutdata`}
          icon="icon-usd"
          label={_('account:navigation.payoutdata')}
        />}
        */}
      </NavSub>
    );
  }
}

export default NavSubFinances;
export { NavSubFinances };
