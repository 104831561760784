import React, { FC, useState } from 'react';
import { css } from '@emotion/core';
import { _ } from '../../util/translate';
import { Main, Section } from '../../atoms';
import SuccessRedirect from './SucessRedirect';
import WelcomeContent from './WelcomeContent';
import { BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';

const sectionStyles = css`
  flex-direction: column;
  padding: 61px 50px;

  @media ${BREAKPOINT_PHONE_CONDITION} {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 30px 50px;
  }
`;

const Welcome: FC = () => {
  const [wasDocsUploadedSuccessful, setwasDocsUploadedSuccessful] = useState<boolean>(false);
  const onDocsUploadedSuccess = (): void => setwasDocsUploadedSuccessful(true);
  const [wasProfilePicUploadSuccessful, setWasProfilePicUploadSuccessful] =
    useState<boolean>(false);
  const onProfilePicUploadSuccess = (): void => setWasProfilePicUploadSuccessful(true);
  return (
    <Main isMobileEnabled={true}>
      <Section title={_('components:quickstart.title')} styles={sectionStyles}>
        {wasDocsUploadedSuccessful && wasProfilePicUploadSuccessful ? (
          <SuccessRedirect />
        ) : (
          <WelcomeContent
            onDocsUploadedSuccess={onDocsUploadedSuccess}
            onProfilePicUploadSuccess={onProfilePicUploadSuccess}
          />
        )}
      </Section>
    </Main>
  );
};

export default Welcome;
