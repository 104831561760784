import React, { FC } from 'react';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import Box from '@material-ui/core/Box';
import WhiteBox from '../../WhiteBox';
import styled from '@emotion/styled';
import { BLACK_1 } from '../../../../../camtool-styles';
import menuAnimationGif from './assets/menueAnimationSmaller.gif';
import { Link } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../../util/env';
import { _ } from '../../../../../util/translate';
import { Alert } from '../../../../../atoms';
import { Spinner } from '../../../../../components';
import { SpinnerContainer } from '../../../../../packages/Telegram/components/BroadcastShow/CancelBroadcastModal';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 30p 0 0;
`;

const Title = styled.h3`
  font-size: 18px;
  max-width: 158px;
  text-align: center;
  margin-bottom: 16px;
  color: ${BLACK_1};
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${BLACK_1};
`;

interface DocsTile {
  loading: boolean;
  rejectedDocumentsExist: boolean;
  checkingDocumentsExist: boolean;
}

const DocumentsTile: (IDocsTile: DocsTile) => JSX.Element = (IDocsTile: DocsTile) => {
  const showAlert = IDocsTile.rejectedDocumentsExist || IDocsTile.checkingDocumentsExist;
  const severity = IDocsTile.rejectedDocumentsExist ? 'error' : 'warning';
  const message = IDocsTile.rejectedDocumentsExist
    ? _('welcome:assistent.rejectedDocuments')
    : _('welcome:assistent.verifyInfo');

  return (
    <LinkStyled to={APP_BASE_PATH + '/account/documents'}>
      <WhiteBox>
        <Container>
          {showAlert ? (
            IDocsTile.loading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <Alert severity={severity}>{message}</Alert>
            )
          ) : null}
          <Box mb={1} mt={1}>
            <FolderSpecialIcon fontSize="large" />
          </Box>
          <Title>{_('welcome:assistent.yourDocs')}</Title>
          <img src={menuAnimationGif} alt="Account Documents" />
        </Container>
      </WhiteBox>
    </LinkStyled>
  );
};

export default DocumentsTile;
