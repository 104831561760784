import React, { Fragment, useState } from 'react';
import { Button, Modalbox, T, Tooltip, VideoPlayer } from '../../../components';

import academyLogo from '../../../assets/images/academy-logo.png';
import lightIcon from '../../../assets/images/light-icon.png';
import academyVideoThumbnail from '../../../assets/images/video-academy-thumbnail.png';

import { _ } from '../../../util/translate';



const AcademyTooltipAndModal = () => {
  const [showModal, updateShowModal] = useState(false);
  const openModal = () => updateShowModal(true);
  const closeModal = () => updateShowModal(false);

  return (
    <Fragment>
      <Tooltip
        className="video-explorer__header__education"
        position="top-right"
        icon="icon-education"
      >
        <div className="accademy__tooltip__header">
          <img src={academyLogo} alt="icon" />
        </div>
        <div className="accademy__tooltip_content">
          <div className="accademy__tooltip_content__label">
            <img className="accademy__tooltip_content__label__icon" src={lightIcon} alt="icon" />
            Tutorialvideo
          </div>
          <div className="accademy__tooltip_content__title">
            "<T _={'academy:video.title.upload'} />"
          </div>
          <img
            className="accademy__tooltip_content__image"
            alt="academy video thumbnail"
            onClick={openModal}
            src={academyVideoThumbnail}
          />
          <Button onClick={openModal} className="button--blue" label="Zum Video" />
        </div>
      </Tooltip>

      <Modalbox name="academyModal" state={showModal} onKeyPressESC={closeModal}>
        <div className="modalgrid__box">
          <div className="modalgrid__box__header vxmodels-academy__header">
            {`${_('academy:modalBox.name')} ${_('academy:video.title.upload')}`}
            <div className="seperator" />
            <div
              className="icon-remove options vxmodels-academy__header__btn"
              onClick={closeModal}
            />
          </div>

          <div className="modalgrid__box__content">
            <div className="modalgrid__box__content__column">
              <VideoPlayer url="/static/videos/how_to_upload_a_video.mp4" />
            </div>
          </div>
        </div>
      </Modalbox>
    </Fragment>
  );
};

export default AcademyTooltipAndModal;
