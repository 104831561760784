import React, { FC } from 'react';
import {
  IconCommentSubmitActive,
  IconCommentSubmitInactive,
} from '../../../routes/MediaManagement/Video/View/Icons';
import { _ } from '../../../util/translate';
import { BLUE_HOVER } from '../../../camtool-styles';

interface SendIconProps {
  disabled: boolean;
  title: string;
  onClick: () => void;
  className?: string;
}

const SendButton: FC<SendIconProps> = ({ disabled, title, onClick, className }) => {
  return (
    <button
      className={className}
      title={title}
      type="button"
      css={{
        border: 'none',
        padding: 1,
        borderRadius: 20,
        marginRight: -8,
        cursor: disabled ? 'cursor' : 'pointer',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {disabled ? <IconCommentSubmitInactive /> : <IconCommentSubmitActive />}
    </button>
  );
};

export default SendButton;
