import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { coins } from '../../../../../atoms/Icon/libraries/glyph';
import { useUserData } from '../../../../../util/UserData';

const GoToVXCashItem: FC = () => {
  const userData = useUserData();
  const autologinLink = userData?.model?.vxcash?.autologinLink;

  return (
    autologinLink && (
      <LeftMenuItem
        title={_('navigation:affiliate.goToVXCash')}
        icon={coins}
        onClick={() => window.open(autologinLink, '_blank')}
      />
    )
  );
};

export default GoToVXCashItem;
