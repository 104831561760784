import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { girl } from '../../../../../atoms/Icon/libraries/glyph';
import { useSpeaksGerman } from '../../../../../util/UserData';

const InviteModelsItem: FC = () => {
  const speaksGerman = useSpeaksGerman();

  return speaksGerman ? (
    <LeftMenuItem
      title={_('navigation:affiliate.inviteModels')}
      icon={girl}
      uri="/vxcash/invite-models"
    />
  ) : null;
};

export default InviteModelsItem;
