import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BREAKPOINT_SPHONE, WHITE } from '../../../camtool-styles';
import { BREAKPOINT } from '../constants';

const Wrapper = styled.div`
  width: 340px;
  height: 340px;
  justify-content: center;
  align-items: center;
  background: ${WHITE};
  margin: 22.5px 0;
  overflow: hidden;
  padding: 0 30px;

  @media (min-width: ${BREAKPOINT}px) {
    margin: 22.5px;
  }

  ${BREAKPOINT_SPHONE} {
    width: 220px;
    padding: 0 10px;
  }
`;

const WhiteBox: FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default WhiteBox;
