import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';

import ProfilesOverview from './ProfilesOverview/ProfilesOverview';
import Common from './Common';
import FL from './FL';
import LA from './LA';
import VX from './VX';

const OnlineProfile = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/overview`} component={ProfilesOverview} />
      <ProtectedRoute path={`${path}/common`} component={Common} />
      <ProtectedRoute path={`${path}/lustagenten`} component={LA} />
      <ProtectedRoute path={`${path}/flirtlife`} component={FL} />
      <ProtectedRoute path={`${path}/visitx`} component={VX} />
      <Redirect to={`${path}/overview`} />
    </Switch>
  );
};
export default OnlineProfile;
