import React, { FC, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { _ } from '../../util/translate';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { QUERY_AVS_REDIRECT } from '../../graphql/VXModels/queries';

const SucessRedirect: FC = () => {
  const apolloClient = useApolloClient();

  useEffect(() => {
    const timeout = setTimeout((): void => {
      // Fetch new avs status (triggers route change and LeftMenu update)
      apolloClient
        .query({
          query: QUERY_AVS_REDIRECT,
          fetchPolicy: 'network-only',
        })
        .catch(() => {
          // Reload on error
          window.location.reload();
        });
    }, 3000);

    return (): void => clearTimeout(timeout);
  }, []);

  return (
    <EmptyContent
      title={_('avs:texts.verifySuccessful')}
      subtitle={_('avs:texts.verifySuccessfulSubtitle')}
    />
  );
};

export default SucessRedirect;
