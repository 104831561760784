import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { cogwheels } from '../../../../../atoms/Icon/libraries/glyph';

const VideochatSettingsItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:videochat.settings')}
    icon={cogwheels}
    uri="/settings/chatsettings"
  />
);

export default VideochatSettingsItem;
