import React, { Fragment } from 'react';

import AbstractExplorerView from '../AbstractExplorerView';
import {
  MediaManagementActionCreators,
  RESOURCE_ALBUMS,
} from '../../../../stores/MediaManagement/MediaManagement';
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';
import AlbumForm from '../../../../components/MediaManagement/ExplorerPicture/AlbumForm/AlbumForm';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { APP_BASE_PATH } from '../../../../util/env';
import { _ } from '../../../../util/translate';

class AlbumCreate extends AbstractExplorerView {
  constructor(props, context) {
    super(props, context);
    this.state = Object.assign(this.state, {
      title: _('mediamanagement:picfolder.nav.createNewAlbum'),
      albumType: 'create',
      isLoading: false,
    });
  }

  componentDidMount() {
    // this.context.routeDispatcher.emit('onSelectFolder', this.state);
    this.props.setCurrentAlbumType('create');
  }

  onSubmit(album) {
    // console.log(this.__proto__.constructor.name, 'onSave', album);
    this.setState({ isLoading: true });
    MediaManagementActionCreators.createResource(
      RESOURCE_ALBUMS,
      { data: album },
      data => {
        AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
        this.props.history.push(`${APP_BASE_PATH}/mediamanagement/picture/album/${data.album.id}`);
      },
      () => {
        AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    const { title, titleTooltip, album, editable, isLoading } = this.state;
    const isRejected = album && album.status === 'rejected';
    const isNotRejected = album && album.status !== 'rejected';

    return (
      <div className="grid__box__column grid__box__sub">
        <div className="grid__box__sub__header">
          <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
            {title}
            {isRejected && (
              <Fragment>
                (<Translation _={`mediamanagement:picfolder.common.status${album.status}`} />)
              </Fragment>
            )}
          </div>

          {titleTooltip && <Tooltip position="right" text={titleTooltip} />}

          <div className="grid__box__sub__header__spacer" />

          {isRejected && (
            <Fragment>
              {editable && (
                <div
                  className="icon-bin grid__box__sub__header__item--option"
                  onClick={this.onClickDeleteAlbum}
                >
                  <T _={'common:button.delete'} />
                </div>
              )}
            </Fragment>
          )}
          {isNotRejected && (
            <Fragment>
              {editable && (
                <div
                  className="icon-pencil grid__box__sub__header__item--option"
                  onClick={this.setEditMode}
                >
                  <T _={'mediamanagement:picfolder.title.edit'} />
                </div>
              )}
            </Fragment>
          )}
        </div>

        <AlbumForm showAlbumType onSubmit={this.onSubmit} isLoading={isLoading} />
      </div>
    );
  }
}

export default AlbumCreate;
