import React from 'react';
import { T } from '../../../../components';
import NavSubFL from '../NavSubFL';

const Profilepic = ({ children }) => {
  return (
    <main id="la-profile-pic" className="la-profile-pic grid">
      <NavSubFL />
      <section className="grid__row">
        <section className="grid__column grid__box">
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:la.profilepic.pagetitle.label'} />
            </div>
          </header>

          <div className="grid__box__item spinner-container">
            <div className="grid__box__item__content">
              {' '}
              {/* <div class="grid__box__item__content vx-profile-pic__formwidth"> */}
              {children}
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default Profilepic;
