import React, { FC, useState, useEffect, useRef } from 'react';
import { VXCashStore } from '../../../stores/VXCash/VXCashStore';
import { VXCashActionCreators } from '../../../stores/VXCash/VXCashActionCreators';
import Spinner from '../../../components/Spinner/Spinner';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import { _ } from '../../../util/translate';

const STORE = 'adlinks';

const GoToVXCash: FC = () => {
  const [error, setError] = useState<string>();

  const onStoreChange = () => {
    const { vxcashAutologinLink } = VXCashStore.get(STORE);
    if (vxcashAutologinLink) {
      window.location = vxcashAutologinLink;
    } else {
      setError(_('common:error.generic'));
    }
  };

  useEffect(() => {
    VXCashStore.addChangeListener(STORE, onStoreChange);
    VXCashActionCreators.getVXCashAdlinks();

    return () => VXCashStore.removeChangeListener(STORE, onStoreChange);
  }, []);

  return (
    <main className="grid">
      {error ? <EmptyContent icon="icon-warning-sign" title={_(error)} /> : <Spinner />}
    </main>
  );
};

export default GoToVXCash;
