import { VXGChallengeTypeEnum } from './../../graphql/VXModels/types';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { de } from 'date-fns/locale';
import { BLACK, GRAY_2, GREEN, RED, WHITE, WHITE_2, GRAY_4, BLUE } from '../../camtool-styles';
import {
  banner_bas,
  banner_livechat,
  banner_messenger,
  banner_ticketShow,
  tile_messenger,
  detail_messenger,
  tile_ticketshow,
  detail_ticketShow,
  tile_bas,
  detail_bas,
  detail_recruitGuest,
  tile_recruitGuest,
  banner_recruitGuest,
  detail_livechat,
  tile_livechat,
  detail_existingCustomer,
  tile_existingCustomer,
  banner_existingCustomer,
  detail_newGuest,
  tile_newGuest,
  banner_newGuest,
  banner_videoSales,
  detail_videoSales,
  tile_videoSales,
} from './Assets/';
/** define design token variables and repeating logic here **/

/** constants */
export const VXGAMES_CURRENT_DATE = new Date();

/** design tokens **/

// COLORS
export const VXGAMES_PRIMARY = '#5C62E1';
export const VXGAMES_DARK_GREY = '#0A183B';
export const VXGAMES_GREY = '#E7EDF3';
export const VXGAMES_LILA_1 = '#3A394F';
export const VXGAMES_LILA_2 = '#44465D';
export const VXGAMES_GRADIENT = 'linear-gradient(164deg, #5C62E1 16%, #9DAAFF 72%)';
export const VXGAMES_ERROR_COLOR = RED;
export const VXGAMES_INFO_COLOR = '#FFB033';
export const VXGAMES_OK_COLOR = GREEN;
export const VXGAMES_BUTTON_PRIMARY_COLOR = VXGAMES_LILA_2;
export const VXGAMES_BUTTON_PRIMARY_TEXT_COLOR = WHITE;
export const VXGAMES_BUTTON_DEFAULT_COLOR = '#E0E1E2';
export const VXGAMES_BUTTON_DEFAULT_TEXT_COLOR = '#5A5A5A';
export const VXGAMES_BUTTON_SECONDARY_COLOR = WHITE_2;
export const VXGAMES_BUTTON_SECONDARY_TEXT_COLOR = '#5A5A5A';

// TYPOGRAPHY
export const VXGAMES_TYPOGRAPHY_H1 = {
  fontSize: '28px',
  fontWeight: 'bold',
  color: BLACK,
  lineHeight: 1.3,
};

export const VXGAMES_TYPOGRAPHY_H2 = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_H3 = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_H4 = {
  fontSize: '15px',
  fontWeight: 'bold',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_H5 = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_SUBHEADING = {
  fontSize: '16px',
  color: GRAY_2,
};

export const VXGAMES_TYPOGRAPHY_PARAGRAPH = {
  fontSize: '16px',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_SMALL_PARAGRAPH = {
  fontSize: '14px',
  color: WHITE,
};

export const VXGAMES_TYPOGRAPHY_BLUE_PARAGRAPH = {
  fontSize: '16px',
  color: BLUE,
};

export const VXGAMES_TYPOGRAPHY_STRONG_PARAGRAPH = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: BLACK,
};

export const VXGAMES_TYPOGRAPHY_GREY_SUBHEADING = {
  fontSize: '20px',
  color: GRAY_4,
};

export const VXGAMES_TYPOGRAPHY_ORANGE_SUBHEADING = {
  fontSize: '20px',
  color: VXGAMES_PRIMARY,
};

export const VXGAMES_TYPOGRAPHY_ORANGE_H2 = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: VXGAMES_PRIMARY,
};

export const VXGAMES_TYPOGRAPHY_FOOTNOTE = {
  fontSize: '12px',
  color: GRAY_2,
};

export const VXGAMES_TYPOGRAPHY_GREY2_SUBHEADING = {
  fontSize: '20px',
  color: GRAY_2,
};

export const VXGAMES_TYPOGRAPHY_BADGE = {
  color: WHITE,
  fontSize: '12px',
};

/* helper functions / objects */
export const formatDate = (date: Date, formatString: string): string =>
  format(utcToZonedTime(parseISO(date), 'UTC'), formatString, { locale: de });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertArrayIntoSpokenArray = (personList: Array<{ userName: string }>) => {
  let spokenList = '';
  const lastElementIndex = personList.length - 1;
  const secondLastElementIndex = personList.length - 2;

  personList.forEach((person, index) =>
    index < lastElementIndex
      ? (spokenList +=
          index < secondLastElementIndex ? `${person.userName}, ` : `${person.userName}`)
      : (spokenList += lastElementIndex ? ` und ${person.userName}` : `${person.userName}`)
  );

  return spokenList;
};

export const competitionImageMapping = {
  [VXGChallengeTypeEnum.basProvision]: {
    detailImage: detail_bas,
    tileImage: tile_bas,
    bannerImage: banner_bas,
  },
  [VXGChallengeTypeEnum.recruitedUsers]: {
    detailImage: detail_recruitGuest,
    tileImage: tile_recruitGuest,
    bannerImage: banner_recruitGuest,
  },
  [VXGChallengeTypeEnum.livechatProvision]: {
    detailImage: detail_livechat,
    tileImage: tile_livechat,
    bannerImage: banner_livechat,
  },
  [VXGChallengeTypeEnum.oldCustomerProvision]: {
    detailImage: detail_existingCustomer,
    tileImage: tile_existingCustomer,
    bannerImage: banner_existingCustomer,
  },
  [VXGChallengeTypeEnum.recruitedModels]: {
    detailImage: detail_newGuest,
    tileImage: tile_newGuest,
    bannerImage: banner_newGuest,
  },
  [VXGChallengeTypeEnum.newCustomerProvision]: {
    detailImage: detail_newGuest,
    tileImage: tile_newGuest,
    bannerImage: banner_newGuest,
  },
  [VXGChallengeTypeEnum.livechatOnlinetime]: {
    detailImage: detail_newGuest,
    tileImage: tile_newGuest,
    bannerImage: banner_newGuest,
  },
  [VXGChallengeTypeEnum.shopProvision]: {
    detailImage: detail_videoSales,
    tileImage: tile_videoSales,
    bannerImage: banner_videoSales,
  },
  [VXGChallengeTypeEnum.videosSales]: {
    detailImage: detail_videoSales,
    tileImage: tile_videoSales,
    bannerImage: banner_videoSales,
  },
  [VXGChallengeTypeEnum.ticketShow]: {
    detailImage: detail_ticketShow,
    tileImage: tile_ticketshow,
    bannerImage: banner_ticketShow,
  },
  [VXGChallengeTypeEnum.messengerContent]: {
    detailImage: detail_messenger,
    tileImage: tile_messenger,
    bannerImage: banner_messenger,
  },
};
