import React, { FC } from 'react';
import { Main, Section } from '../../../atoms';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Spinner } from '../../../components';
import { ChatSettings, Maybe } from '../../../graphql/VXModels/types';

import ChatSettingsTable from '../../../packages/ChatSettings/ChatSettingsTable';
import { _ } from '../../../util/translate';
import useGetChatSettings from './hooks/useChatSettings';

const ChatSettingsContainer: FC = () => {
  const { data, loading: chatSettingsLoading, error } = useGetChatSettings();
  const chatSettingList = data?.model?.settings?.chatSettings;
  const initSliderEntries = data?.model?.dashboard?.prices;
  const initialSettingEntries: Maybe<ChatSettings> | undefined =
    data?.model?.settings?.chatSettings;
  const isOnlineWithSSW = data?.model?.isOnlineWithSSW;

  type NewPriceRange = {
    value: number;
    label: string;
  };

  const text = (lang) =>
    initialSettingEntries?.heatmap?.texts
      .map((item) => {
        if (item.language === lang && item.text && item) {
          return item.text;
        }
      })
      .filter((item) => item !== undefined && item)
      .toString();

  const defaultTexts = [
    {
      language: 'de',
      text: text('de'),
      __typename: 'LocalizedText',
    },
    {
      language: 'en',
      text: text('en'),
      __typename: 'LocalizedText',
    },
  ];

  //Data clean up from GraphQL
  const formateDataForFormik = () => {
    if (initialSettingEntries && data) {
      Object.keys(initialSettingEntries).map((obj) => {
        if (obj !== '__typename') {
          initialSettingEntries[obj].status =
            initialSettingEntries[obj].status === 'enabled' ||
            initialSettingEntries[obj].status === true;
        }
        if (obj === 'heatmap') {
          initialSettingEntries[obj].texts = defaultTexts;
        }
      });
    }
  };
  const fixSliderMarks = () => {
    const newArray: Array<Maybe<NewPriceRange>> = [];
    initSliderEntries?.messengerPriceRange.forEach((item) => {
      newArray.push({
        value: item.rangeValue,
        label: `${item.rangeValue}`,
      });
    });
    if (initSliderEntries && newArray.length) {
      initSliderEntries['messengerPriceRange'] = newArray;
    }
  };

  if (initSliderEntries?.messengerPriceRange[0].rangeKey === 'min') fixSliderMarks();
  formateDataForFormik();
  // END Cleanup

  const initData = { settings: chatSettingList, sliders: initSliderEntries };

  return chatSettingsLoading ? (
    <Spinner />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <Main isMobileEnabled>
      <Section title={_('settings:chatSettings.pageTitle.label')}>
        <ChatSettingsTable items={initData} isOnlineWithSSW={isOnlineWithSSW} />
      </Section>
    </Main>
  );
};

export default ChatSettingsContainer;
