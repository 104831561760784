import React, { FC } from 'react';
import { Container, Content } from '../WidgetsMedium/Layout';
import Icon from '../../../atoms/Icon/Icon';
import { lock } from '../../../atoms/Icon/libraries/svg';
import { GRAY_2 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import styled from '@emotion/styled';

const BlockedCountriesWrap = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  blockedCountries: string[];
}

const BlockedCountries: FC<IProps> = ({ blockedCountries }) => {
  return (
    <Container css={{ width: '100%', minHeight: '100px', margin: '8px 0' }}>
      <Content>
        <BlockedCountriesWrap>
          <Icon css={{ marginBottom: '5px' }} icon={lock} />
          <span css={{ color: `${GRAY_2}` }}>
            {_('dashboard:app.blockedCountries.information')}
          </span>
          <div css={{ fontWeight: 'bold', fontSize: '24px' }}>
            {blockedCountries.map((country: string, index: number) => (
              <span key={country}>
                {index > 0 ? <span css={{ margin: '0 5px' }}> | </span> : null}
                <span>{_(`enum:common.country.${country}`)}</span>
              </span>
            ))}
          </div>
        </BlockedCountriesWrap>
      </Content>
    </Container>
  );
};

export default BlockedCountries;
