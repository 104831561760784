import React from 'react';
import NavSub from '../../../components/NavSub/NavSub';
import NavSubItem from '../../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

class NavSubFL extends React.Component {
  render() {
    return (
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/flirtlife/common`}
          icon="icon-nameplate"
          label={_('profiles:fl.navigation.profile')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/flirtlife/profilepic`}
          icon="icon-pictures"
          label={_('profiles:fl.navigation.pictures')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/flirtlife/automails`}
          icon="icon-envelope"
          label={_('profiles:fl.navigation.automails')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/flirtlife/settings`}
          icon="icon-settings"
          label={_('profiles:fl.navigation.settings')}
        />
      </NavSub>
    );
  }
}

export default NavSubFL;
export { NavSubFL };
