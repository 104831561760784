import { _ } from '../../../../../util/translate';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MISSING_DOCUMENTS } from '../../../../../graphql/VXModels/queries';
import {
  DocumentStatusEnum,
  DocumentTypeGroup,
  Document,
  Query,
  Maybe,
} from '../../../../../graphql/VXModels/types';
import { AssistantDocs } from '../../../../../components/Assistant/Assistant';

type MissingDocumentsExist = {
  loading: boolean;
  rejectedDocumentsExist: boolean;
  checkingDocumentsExist: boolean;
};

export const useAssistantIncompleteRequiredTypes = (): MissingDocumentsExist => {
  const { data, loading } = useQuery<Query>(QUERY_MISSING_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const missingDocumentsOfOriginator =
    data?.model?.documents?.documentsOfOriginator?.incompleteRequiredTypes;
  const missingDocumentsOfActor =
    data?.model?.documents?.documentsOfActors[0]?.incompleteRequiredTypes;

  const checkForStatus = (
    status: DocumentStatusEnum,
    missingDocuments: DocumentTypeGroup[]
  ): DocumentTypeGroup[] =>
    missingDocuments?.filter(
      (document: DocumentTypeGroup) =>
        document.status.includes(status) && AssistantDocs.includes(document.type)
    );

  const checkingOriginatorAssistentDocuments = checkForStatus(
    DocumentStatusEnum.checking,
    missingDocumentsOfOriginator
  );
  const checkingActorAssistentDocuments = checkForStatus(
    DocumentStatusEnum.checking,
    missingDocumentsOfActor
  );
  const checkingDocumentsExist =
    checkingOriginatorAssistentDocuments?.length > 0 || checkingActorAssistentDocuments?.length > 0;

  const missingOriginatorAssistentDocuments = checkForStatus(
    DocumentStatusEnum.missing,
    missingDocumentsOfOriginator
  );
  const rejectedOriginatorAssistentDocuments = missingOriginatorAssistentDocuments?.map(
    (document: DocumentTypeGroup) =>
      document.documents?.filter(
        (subDocument: Maybe<Document>) => subDocument?.rejectionReason !== null
      )
  );

  const missingActorAssistentDocuments = checkForStatus(
    DocumentStatusEnum.missing,
    missingDocumentsOfActor
  );
  const rejectedActorAssistentDocuments = missingActorAssistentDocuments?.map(
    (document: DocumentTypeGroup) =>
      document.documents?.filter(
        (subDocument: Maybe<Document>) => subDocument?.rejectionReason !== null
      )
  );

  const rejectedDocumentsExist = // [0] is allright for newest version/status of uploaded document type
    rejectedOriginatorAssistentDocuments?.[0]?.length > 0 ||
    rejectedActorAssistentDocuments?.[0]?.length > 0;
  return {
    loading,
    rejectedDocumentsExist,
    checkingDocumentsExist,
  };
};
