import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { cup } from '../../../../atoms/Icon/libraries/glyph';

const RankingItem: FC = () => (
  <LeftMenuItem title={_('navigation:main.ranking')} icon={cup} uri="/ranking" />
);

export default RankingItem;
