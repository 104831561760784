import React, { FC } from 'react';
import { Box, Typography, Button, createStyles, makeStyles } from '@material-ui/core';
import {
  VXGAMES_TYPOGRAPHY_GREY2_SUBHEADING,
  VXGAMES_TYPOGRAPHY_ORANGE_SUBHEADING,
  VXGAMES_TYPOGRAPHY_H1,
  VXGAMES_TYPOGRAPHY_H3,
  VXGAMES_TYPOGRAPHY_STRONG_PARAGRAPH,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
  VXGAMES_TYPOGRAPHY_SMALL_PARAGRAPH,
  VXGAMES_PRIMARY,
  competitionImageMapping,
} from '../../../../utils';
import { background } from '../../../../Assets/index';
import {
  VXGChallengeStatusEnum,
  VXGChallengeUserStatusEnum,
  VXGChallenge,
  Mutation,
} from '../../../../../../graphql/VXModels/types';
import { MUTATION_MODEL_VXCHALLENGE_PARTICIPATE } from '../../../../graphql';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../../../util/env';

interface GridProps {
  challenge: VXGChallenge;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      textAlign: 'center',
      borderRadius: 10,
      maxWidth: 416,
      height: '100%',
      margin: 'auto',
    },
    top: {
      padding: 15,
    },
    img: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '405px',
    },
    texts: {
      padding: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    button: {
      borderRadius: 4,
      marginBottom: 20,
      backgroundColor: VXGAMES_PRIMARY,
      '&:hover': {
        backgroundColor: VXGAMES_PRIMARY,
      },
    },
  })
);

const GridItem: FC<GridProps> = ({ challenge }) => {
  const classes = useStyles();
  const history = useHistory();

  const [participate] = useMutation<Mutation>(MUTATION_MODEL_VXCHALLENGE_PARTICIPATE, {
    variables: {
      challengeId: challenge.id,
    },
  });

  const onClickParticipate = () => {
    participate();
  };

  const onClickToChallenge = () => {
    history.push(`${APP_BASE_PATH}/vxgames/${challenge.id}`);
  };

  const modelNotParticipating =
    challenge?.userStatistic?.status === VXGChallengeUserStatusEnum.notParticipated;
  const modelWon = challenge?.userStatistic?.status === VXGChallengeUserStatusEnum.winner;

  return (
    <Box className={classes.root}>
      {challenge.status === VXGChallengeStatusEnum.active ? (
        <Typography style={VXGAMES_TYPOGRAPHY_ORANGE_SUBHEADING} className={classes.top}>
          Aktuelle Herausforderung
        </Typography>
      ) : (
        <Typography style={VXGAMES_TYPOGRAPHY_GREY2_SUBHEADING} className={classes.top}>
          {challenge.status === VXGChallengeStatusEnum.planned
            ? 'Startet bald'
            : 'Vergangene Herausforderung'}
        </Typography>
      )}
      <Box className={classes.img}>
        <img src={competitionImageMapping[challenge.type].tileImage} alt="" />
      </Box>
      <Box className={classes.texts}>
        <Typography style={VXGAMES_TYPOGRAPHY_H1} gutterBottom>
          {challenge.texts.title}
        </Typography>
        <Typography style={VXGAMES_TYPOGRAPHY_STRONG_PARAGRAPH}>
          {challenge.texts.subTitle}
        </Typography>
        {challenge.status === VXGChallengeStatusEnum.finished &&
        challenge.participantsList.length > 0 ? (
          <Box pt={'30px'}>
            <Typography style={VXGAMES_TYPOGRAPHY_H3} gutterBottom>
              Die Herausforderung wurde beendet
            </Typography>
            <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH} gutterBottom>
              {modelWon
                ? 'Du bist unter den Gewinnerinnen, Herzlichen Glückwunsch!'
                : 'Die Gewinnerinnen wurden ausgelost und benachrichtigt.'}
            </Typography>
          </Box>
        ) : (
          <Box pt={'30px'}>
            <Typography style={VXGAMES_TYPOGRAPHY_H3} gutterBottom>
              Das Preisgeld:
            </Typography>
            <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH} gutterBottom>
              {challenge.numberOfWinners} x {challenge.rewardValue} € - Nimmst du die
              Herausforderung an?
            </Typography>
          </Box>
        )}
      </Box>
      {challenge.status === VXGChallengeStatusEnum.active ? (
        <Button
          className={classes.button}
          disabled={!challenge.userStatistic?.isTocAccepted}
          onClick={modelNotParticipating ? onClickParticipate : onClickToChallenge}
        >
          <Typography style={VXGAMES_TYPOGRAPHY_SMALL_PARAGRAPH}>
            {modelNotParticipating ? 'Jetzt starten' : 'Zur Herausforderung'}
          </Typography>
        </Button>
      ) : null}
    </Box>
  );
};

export { GridItem };
