import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { user } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import {
  CommonProfileItem,
  LustagentenProfileItem,
  ProfileOverviewItem,
  VisitXProfileItem,
} from '../items';

const ProfileGroup: FC = () => (
  <LeftMenuGroup title={_('navigation:main.profile.header')} icon={user}>
    <ProfileOverviewItem />
    <CommonProfileItem />
    <VisitXProfileItem />
    <LustagentenProfileItem />
  </LeftMenuGroup>
);

export default ProfileGroup;
