import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { coins } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { AffiliateFragment } from '../fragments';

const AffiliateGroup: FC = () => {
  return (
    <LeftMenuGroup title={_('navigation:main.affiliate')} icon={coins}>
      <AffiliateFragment />
    </LeftMenuGroup>
  );
};

export default AffiliateGroup;
