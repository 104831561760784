import React from 'react';

import { PROFILE_FL } from '../../../../stores/Profile/Profile';
import { SessionStore } from '../../../../stores/Session/Session';

import AbstractProfile from '../../AbstractProfile';
import MultiLangTextareafield from '../../../../components/Form/MultiLang/MultiLangTextareafield/MultiLangTextareafield';
import Inputfield from '../../../../components/Form/Text/Inputfield/Inputfield';
import NavSubFL from '../NavSubFL';
import {
  Button,
  ButtonGroup,
  Dropdown,
  Form,
  GroupRow,
  Separator,
  Spinner,
  T,
} from '../../../../components';
import { _ } from '../../../../util/translate';
import { APP_BASE_PATH } from '../../../../util/env';

export default class Common extends AbstractProfile {
  getResource() {
    return PROFILE_FL;
  }

  componentWillMount() {
    if (!SessionStore.get().profile.isFLProfileActive) {
      this.props.history.push(`${APP_BASE_PATH}/onlineprofile/flirtlife/settings`);
    }
    super.componentWillMount();
  }

  render() {
    this.spokenLangs = ['de'];
    super.render();
    return (
      <main id="la-profile" className="la-profile grid">
        <NavSubFL />

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:fl.profile.pagetitle.label'} />
              </div>
            </header>

            <Form
              className="grid__box__item spinner-container"
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content la-profile__formwidth">
                <GroupRow
                  id="FLNickName"
                  label={_('profiles:fl.nickname.label') + ':'}
                  className={this.getPropertyErrorMessage('flNickname') !== '' ? 'error' : ''}
                  errorMessage={this.getPropertyErrorMessage('flNickname')}
                >
                  <Inputfield
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    validations="isAlphanumericOrDash,maxLength:31,minLength:4"
                    validationError={_('profiles:fl.nickname.validationError')}
                    validationErrors={{
                      isAlphanumeric: _('common:validationMessage.isAlphanumeric'),
                      minLength: _('common:validationMessage.minLength4'),
                    }}
                    displayErrors={true}
                    value={this.storeValues.flNickname}
                    name="flNickname"
                    placeholder={_('profiles:fl.nickname.label')}
                    type="text"
                    minLength="4"
                    onChange={this.changeNickname}
                  />
                </GroupRow>

                <GroupRow
                  id="FLAboutMe"
                  label={_('profiles:fl.aboutMeText.label') + ':'}
                  className={this.getTextErrorMessage('flAboutMeText') !== '' ? 'error' : ''}
                  errorMessage={this.getTextErrorMessage('flAboutMeText')}
                >
                  <MultiLangTextareafield
                    requed
                    requiredMessage={_('common:formRequiredMessage.common')}
                    validations="isValidMultiLangField"
                    validationError={_('common:validationMessage.isValidMultiLangField')}
                    displayErrors={true}
                    name="flAboutMeText"
                    value={this.storeValues.flAboutMeText.texts}
                    placeholder={_('profiles:fl.aboutMeText.placeholder')}
                    langs={this.spokenLangs}
                    maxLength={1000}
                  />
                </GroupRow>

                <GroupRow
                  id="FLIncome"
                  label={_('profiles:fl.turnover.label') + ':'}
                  className={this.hasInputError('flTurnover') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flTurnover"
                    value={this.storeValues.flTurnover}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flTurnover}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow
                  id="FLSmoke"
                  label={_('profiles:fl.smoke.label') + ':'}
                  className={this.hasInputError('flSmoke') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flSmoke"
                    value={this.storeValues.flSmoke}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flSmoke}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow
                  id="FLReligion"
                  label={_('profiles:fl.religion.label') + ':'}
                  className={this.hasInputError('flReligion') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flReligion"
                    value={this.storeValues.flReligion}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flReligion}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow
                  id="FLEducation"
                  label={_('profiles:fl.education.label') + ':'}
                  className={this.hasInputError('flEducation') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flEducation"
                    value={this.storeValues.flEducation}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flEducation}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow
                  id="FLOrigin"
                  label={_('profiles:fl.origin.label') + ':'}
                  className={this.hasInputError('flOrigin') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flOrigin"
                    value={this.storeValues.flOrigin}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flOrigin}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow
                  id="FLAlcohol"
                  label={_('profiles:fl.alcohol.label') + ':'}
                  className={this.hasInputError('flAlcohol') ? 'error' : ''}
                >
                  <Dropdown
                    required
                    requiredMessage={_('common:formRequiredMessage.common')}
                    name="flAlcohol"
                    value={this.storeValues.flAlcohol}
                    forceTranslate={true}
                    options={this.storeValues.formValues.flAlcohol}
                    placeholder={_('common:componentsDefault.Dropdown.placeholder')}
                  />
                </GroupRow>

                <GroupRow>
                  <Separator className="transparent" />
                </GroupRow>

                <GroupRow tooltip="none" label=" ">
                  <ButtonGroup position="right">
                    <Button
                      className="button--blue"
                      label={_('common:button.save')}
                      type="submit"
                    />
                    <Button
                      type="button"
                      label={_('common:button.cancel')}
                      onClick={this.rejectChanges}
                      disabled={!this.activateResetButton}
                    />
                  </ButtonGroup>
                </GroupRow>
              </div>
            </Form>
          </section>
        </section>
      </main>
    );
  }
}
