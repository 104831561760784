import React, { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import NewsCenter from './NewsCenter';
import Settings from './Settings';

const Index: FC = () => {
  const { path: basePath } = useRouteMatch();

  const notificationsPath = `${basePath}/notifications`;
  const settingsPath = `${basePath}/settings`;

  return (
    <Switch>
      <ProtectedRoute path={notificationsPath} exact component={NewsCenter} />
      <ProtectedRoute path={settingsPath} exact component={Settings} />
      <Redirect to={notificationsPath} />
    </Switch>
  );
};

export default Index;
