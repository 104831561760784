import React from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_FL } from '../../../../stores/Profile/Profile';
import { MediaManagementActionCreators } from '../../../../stores/MediaManagement/MediaManagement';
import { SessionStore } from '../../../../stores/Session/Session';
import isEmpty from 'lodash/isEmpty';

import NavSubFL from '../NavSubFL';

import {
  Button,
  ButtonGroup,
  Form,
  GroupRow,
  MultiLangTextareafield,
  Quote,
  Separator,
  Spinner,
  T,
} from '../../../../components';

import AlbumAdd from '../../../../components/MediaManagement/ExplorerPicture/AlbumAdd/AlbumAdd';
import AlbumItem from '../../../../components/MediaManagement/ExplorerPicture/AlbumItem/AlbumItem';
import GroupColumnNew from '../../../../components/GroupColumnNew/GroupColumnNew';
import InputHidden from '../../../../components/Form/InputHidden/InputHidden';

import { APP_BASE_PATH } from '../../../../util/env';
import {BLACK_1} from "../../../../camtool-styles";
import { _ } from '../../../../util/translate';

class AutoMails extends AbstractProfile {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      detailsPicture: {},
      menuPicture: {},
      poolAlbum: {
        id: 'pool',
        storeType: 'album',
        albumType: 'pool',
        hidePicPreviewMenu: true,
        title: 'unused',
        editable: false,
        pictures: [],
      },
    });

    this.selectItem = this.selectItem.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.onContentClick = this.onContentClick.bind(this);

    this.setPicture = this.setPicture.bind(this);
    this.setPicture1 = picture => {
      return this.setPicture(1, picture);
    };
    this.setPicture2 = picture => {
      return this.setPicture(2, picture);
    };
    this.setPicture3 = picture => {
      return this.setPicture(3, picture);
    };

    this.removePicture = this.removePicture.bind(this);
    this.removePicture1 = picture => {
      return this.removePicture(1, picture);
    };
    this.removePicture2 = picture => {
      return this.removePicture(2, picture);
    };
    this.removePicture3 = picture => {
      return this.removePicture(3, picture);
    };
  }

  getResource() {
    return PROFILE_FL;
  }

  componentWillMount() {
    if (!SessionStore.get().profile.isFLProfileActive) {
      this.props.history.push(`${APP_BASE_PATH}/onlineprofile/flirtlife/settings`);
    }
    super.componentWillMount();
  }

  submitForm(currentValues) {
    currentValues.flWelcomeText1Picture = {
      albumId: currentValues.flWelcomeText1Picture
        ? currentValues.flWelcomeText1Picture.albumId
        : 0,
      pictureId: currentValues.flWelcomeText1Picture
        ? currentValues.flWelcomeText1Picture.pictureId
        : 0,
    };
    currentValues.flWelcomeText2Picture = {
      albumId: currentValues.flWelcomeText2Picture
        ? currentValues.flWelcomeText2Picture.albumId
        : 0,
      pictureId: currentValues.flWelcomeText2Picture
        ? currentValues.flWelcomeText2Picture.pictureId
        : 0,
    };
    currentValues.flWelcomeText3Picture = {
      albumId: currentValues.flWelcomeText3Picture
        ? currentValues.flWelcomeText3Picture.albumId
        : 0,
      pictureId: currentValues.flWelcomeText3Picture
        ? currentValues.flWelcomeText3Picture.pictureId
        : 0,
    };

    super.submitForm(currentValues);
  }

  getItemStatus(item) {
    return item && item.pictureId === this.state.detailsPicture.pictureId ? 'details' : '';
  }

  getItemMenuStatus(item) {
    return item && item.pictureId === this.state.menuPicture.pictureId ? 'show' : '';
  }

  selectItem() {
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  showDetails(item) {
    this.setState({
      menuPicture: {},
      detailsPicture: item,
    });
  }

  hideDetails() {
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  showMenu(item) {
    this.setState({
      menuPicture: item,
      detailsPicture: {},
    });
  }

  isPictureInUse(pictureId) {
    let inUse = false;
    for (let pos of [1, 2, 3]) {
      const field = 'flWelcomeText' + pos + 'Picture';
      inUse = inUse || this.currentValues[field].pictureId === pictureId;
    }

    return inUse;
  }

  setPicture(pos, picture) {
    if (picture[0]) {
      picture = picture[0];
    }

    const field = 'flWelcomeText' + pos + 'Picture';
    let nextValues = {};
    //console.log(this.__proto__.constructor.name, 'refreshAlbum', pos, field, this.currentValues, this.currentValues[field]);
    if (!this.currentValues[field].pictureId && !this.isPictureInUse(picture.pictureId)) {
      picture.notPermanentlyDelete = true;
      nextValues[field] = picture;
      this.updateFormWithValues(nextValues);
    }
  }

  removePicture(pos, picture) {
    if (picture.hasOwnProperty('albumId')) {
      picture['album'] = { id: picture.albumId };
    }

    const field = 'flWelcomeText' + pos + 'Picture';
    let nextValues = {};
    if (!picture.notPermanentlyDelete) {
      MediaManagementActionCreators.deletePictureFromAlbum(picture);
    }
    nextValues[field] = {};
    this.updateFormWithValues(nextValues);
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  onContentClick(event) {
    event.stopPropagation();
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  render() {
    const currentValues = this.currentValues;

    super.render();
    this.spokenLangs = this.state.profileLanguages;
    this.germanLangOnly = ['de'];

    this.hasPicture1 = !isEmpty(currentValues.flWelcomeText1Picture);
    this.hasPicture2 = !isEmpty(currentValues.flWelcomeText2Picture);
    this.hasPicture3 = !isEmpty(currentValues.flWelcomeText3Picture);

    return (
      <main id="la-automails" className="la-automails grid" onClick={this.onContentClick}>
        <NavSubFL />

        <section className="grid__row">
          <section
            className="grid__column grid__box min-width--0"
            css={{ backgroundColor: '#f5f5f5' }}
          >
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:fl.automails.pagetitle.label'} />
              </div>
            </header>

            <div
              css={{
                color: BLACK_1,
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center',
                p: { margin: '2px 0 16px 0' },
                maxWidth: 800,
                alignSelf: 'center',
                padding: '52px 0 0 0',
              }}
            >
              <h3 css={{ paddingBottom: 4 }}>
                Diese Mails werden automatisch an neue Mitglieder versendet
              </h3>
              <p>
                Hinterlege hier Deine Automail. Die Nachricht sollte auffallend, sympathisch und
                anziehend verfasst sein. <br />
                Diese Nachrichten werden automatisch an neue Flirtlife Mitglieder versendet.
                Hinweis: Bitte achte auf eine jugendfreie (FSK 12) Aussprache.
              </p>

              <Quote author="Deine Mrs X :)">
                <h3 css={{ paddingBottom: 8 }}>Beispieltext für eine gute Automail</h3>
                <p css={{ margin: '16px 0', fontSize: 14 }}>
                  Hallo schöner Mann/schöne Frau, <br />
                  du bist mir aufgefallen und ich würde gern mehr über dich erfahren. <br />
                  Ich freue mich auf deine Antwort.
                </p>
              </Quote>
            </div>

            <Form
              className="grid__box__item spinner-container"
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content la-automails__formwidth">
                {/* WELCOME TEXT 1 */}
                <GroupColumnNew
                  label={_('profiles:fl.welcomeText1.label') + ':'}
                  tooltipText={_('profiles:fl.welcomeText1.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('flWelcomeText1') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('flWelcomeText1')}
                  >
                    <MultiLangTextareafield
                      required
                      requiredMessage={_('common:formRequiredMessage.common')}
                      name="flWelcomeText1"
                      value={this.storeValues.flWelcomeText1.texts}
                      placeholder={_('profiles:fl.welcomeText1.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture1 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.flMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="flirtlifeProfile"
                      onSubmit={this.setPicture1}
                    />
                  )}

                  {this.hasPicture1 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.flWelcomeText1Picture,
                        album: { id: this.currentValues.flWelcomeText1Picture.albumId },
                      }}
                      album={{ id: this.currentValues.flWelcomeText1Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.flWelcomeText1Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture1}
                    />
                  )}

                  <InputHidden
                    name="flWelcomeText1Picture"
                    value={this.storeValues.flWelcomeText1Picture}
                  />
                </GroupColumnNew>

                {/* WELCOME TEXT 2 */}
                <GroupColumnNew
                  label={_('profiles:fl.welcomeText2.label') + ':'}
                  tooltipText={_('profiles:fl.welcomeText2.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('flWelcomeText2') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('flWelcomeText2')}
                  >
                    <MultiLangTextareafield
                      name="flWelcomeText2"
                      value={this.storeValues.flWelcomeText2.texts}
                      placeholder={_('profiles:fl.welcomeText2.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture2 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.flMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="flirtlifeProfile"
                      onSubmit={this.setPicture2}
                    />
                  )}

                  {this.hasPicture2 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.flWelcomeText2Picture,
                        album: { id: this.currentValues.flWelcomeText1Picture.albumId },
                      }}
                      album={{ id: this.currentValues.flWelcomeText2Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.flWelcomeText2Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture2}
                    />
                  )}

                  <InputHidden
                    name="flWelcomeText2Picture"
                    value={this.storeValues.flWelcomeText2Picture}
                  />
                </GroupColumnNew>

                {/*  WELCOME TEXT 3 */}
                <GroupColumnNew
                  label={_('profiles:fl.welcomeText3.label') + ':'}
                  tooltipText={_('profiles:fl.welcomeText3.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('flWelcomeText3') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('flWelcomeText3')}
                  >
                    <MultiLangTextareafield
                      name="flWelcomeText3"
                      value={this.storeValues.flWelcomeText3.texts}
                      placeholder={_('profiles:fl.welcomeText3.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture3 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.flMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="flirtlifeProfile"
                      onSubmit={this.setPicture3}
                    />
                  )}

                  {this.hasPicture3 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.flWelcomeText3Picture,
                        album: { id: this.currentValues.flWelcomeText1Picture.albumId },
                      }}
                      album={{ id: this.currentValues.flWelcomeText3Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.flWelcomeText3Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture3}
                    />
                  )}

                  <InputHidden
                    name="flWelcomeText3Picture"
                    value={this.storeValues.flWelcomeText3Picture}
                  />
                </GroupColumnNew>

                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>

                <ButtonGroup position="right">
                  <Button
                    label={_('common:button.cancel')}
                    onClick={this.rejectChanges}
                    type="button"
                    disabled={!this.activateResetButton}
                  />
                  <Button className="button--blue" label={_('common:button.save')} type="submit" />
                </ButtonGroup>
              </div>
            </Form>
          </section>
        </section>
      </main>
    );
  }
}

export default AutoMails;
