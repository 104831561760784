import { VXGChallengeTypeEnum } from './../../../../../../graphql/VXModels/types';
import {
  ba6,
  recruitedGuests,
  liveChatSharing,
  contentSales,
  newCustomerProvision,
  messengerContent,
  ticketShow,
} from './Assets/index';

export const competitionAchievementMapping: { [challenge: string]: number } = {
  [VXGChallengeTypeEnum.basProvision]: ba6,
  [VXGChallengeTypeEnum.recruitedUsers]: recruitedGuests,
  [VXGChallengeTypeEnum.livechatProvision]: liveChatSharing,
  [VXGChallengeTypeEnum.recruitedModels]: recruitedGuests,
  [VXGChallengeTypeEnum.shopProvision]: contentSales,
  [VXGChallengeTypeEnum.videosSales]: liveChatSharing,
  [VXGChallengeTypeEnum.livechatOnlinetime]: contentSales,
  [VXGChallengeTypeEnum.newCustomerProvision]: newCustomerProvision,
  [VXGChallengeTypeEnum.oldCustomerProvision]: recruitedGuests,
  [VXGChallengeTypeEnum.messengerContent]: messengerContent,
  [VXGChallengeTypeEnum.ticketShow]: ticketShow,
};
