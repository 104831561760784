import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const Euro: FC = () => {
  return (
    <SvgIcon>
      <defs>
        <clipPath id="clip-Commission">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="Commission" clipPath="url(#clip-Commission)">
        <path
          id="Pfad_98"
          data-name="Pfad 98"
          d="M775.083,564.979a8.84,8.84,0,0,1-2.708-.412,8.358,8.358,0,0,1-2.328-1.154,9.743,9.743,0,0,1-1.884-1.772,11.377,11.377,0,0,1-1.445-2.264,13.9,13.9,0,0,1-.957-2.64h-4.107l.848-3.174h2.783c0-.058,0-.131-.005-.222s-.006-.186-.006-.28V552.5h-3.619l.848-3.174h3.174a11.105,11.105,0,0,1,3.275-6.17,9.553,9.553,0,0,1,8.673-1.819,8.2,8.2,0,0,1,2.264,1.063,8.434,8.434,0,0,1,3.2,4.031,11,11,0,0,1,.682,2.9h-4.254a4.449,4.449,0,0,0-.508-1.525,5.314,5.314,0,0,0-.99-1.3,4.648,4.648,0,0,0-1.36-.9,3.9,3.9,0,0,0-1.577-.339,7.3,7.3,0,0,0-1.778.19,3.943,3.943,0,0,0-1.377.652,3.669,3.669,0,0,0-1.046,1.253,8.131,8.131,0,0,0-.7,1.97h7.132l-.847,3.174h-6.794c-.006,0-.009.027-.009.08v.206c0,.085,0,.174.005.271l.009.28c0,.091.006.165.006.222h6.572l-.848,3.174h-5.1q.179.464.385.883a8.091,8.091,0,0,0,.472.826,5.8,5.8,0,0,0,.566.741,5.173,5.173,0,0,0,.672.619,4.471,4.471,0,0,0,.779.482,4.234,4.234,0,0,0,.894.305,4.472,4.472,0,0,0,1.005.112,3.893,3.893,0,0,0,3.106-1.3,6.619,6.619,0,0,0,1.381-3.725h4.254a12.385,12.385,0,0,1-.873,3.688,10.151,10.151,0,0,1-1.821,2.953,7.975,7.975,0,0,1-2.682,1.952A8.055,8.055,0,0,1,775.083,564.979Z"
          transform="translate(-760.739 -540.979)"
          fill="#f5f5f5"
        />
      </g>
    </SvgIcon>
  );
};

export default Euro;
