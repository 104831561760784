import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';

import RankingComponent from './Ranking';

const Ranking = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = `${path}/overview`;
  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={indexRoute} />
      <Route path={`${path}/overview`} component={props => <RankingComponent {...props} />} />
      <Redirect to={indexRoute} />
    </Switch>
  );
};

export default Ranking;
