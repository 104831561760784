import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import WhiteBox from '../WhiteBox';
import NewButton from '../../../../atoms/Button/NewButton';
import styled from '@emotion/styled';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { BLACK_1, BREAKPOINT_PHONE_CONDITION, GRAY_2 } from '../../../../camtool-styles';
import { T } from '../../../../components';
import Assistant from '../../../../components/Assistant/Assistant';
import { Props } from '../../../../app/Theme/props/types';
import { useDisplayState } from '../../../../hooks';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 18px;
  max-width: 158px;
  text-align: center;
  margin-bottom: 16px;
  color: ${BLACK_1};
`;
const Description = styled.p`
  text-align: center;
  color: ${GRAY_2};
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 25px;

  @media ${BREAKPOINT_PHONE_CONDITION} {
    font-size: 12px;
  }
`;

const AssistentTile: FC = () => {
  const { shouldShow: showAvsModal, show, hide } = useDisplayState();

  const openModal = (): void => show();

  const closeModal = (): void => hide();

  return (
    <>
      <Container>
        <WhiteBox>
          <Container>
            <Box mb={1}>
              <PersonPinIcon fontSize="large" />
            </Box>
            <Title>
              <T t="welcome:assistent.verifyPerson" />
            </Title>
            <Description>
              <T t="welcome:assistent.verifyDescription" />
            </Description>
            <NewButton
              onClick={async () => {
                await closeModal();
                await openModal();
              }}
              size="medium"
            >
              <T t="welcome:assistent.verifyButton" />
            </NewButton>
          </Container>

          {showAvsModal ? <Assistant /> : null}
        </WhiteBox>
      </Container>
    </>
  );
};

export default AssistentTile;
