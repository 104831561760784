import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { stats } from '../../../../../atoms/Icon/libraries/glyph';

const AffiliateStatsItem: FC = () => (
  <LeftMenuItem title={_('navigation:affiliate.stats')} icon={stats} uri="/vxcash/statistics" />
);

export default AffiliateStatsItem;
