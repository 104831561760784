import React, { FC } from 'react';
import { _ } from '../../util/translate';
import NewButton from '../../atoms/Button/NewButton';
import { NEW_MESSENGER_URL } from '../../config';
import { useAppState } from '../../util/AppState';
import { WHITE } from '../../camtool-styles';
import { useUserData } from '../../util/UserData';
import { Typography } from '@material-ui/core';

export type Size = 'small' | 'medium' | 'large' | undefined;
interface Props {
  size?: Size;
}

const ChatOnlineButton: FC<Props> = ({ size = 'medium' }) => {
  const [{ authToken }] = useAppState();
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;

  return (
    <>
      <NewButton color="secondary" disabled={!isVerified} size={size}>
        <a
          css={{ textDecoration: 'none', color: WHITE }}
          href={`${NEW_MESSENGER_URL}?foreignSID=${authToken}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {_('dashboard:app.chatTile.buttonText')}
        </a>
      </NewButton>
      {!isVerified && <Typography>{_('common:text.notVerified')}</Typography>}
    </>
  );
};

export default ChatOnlineButton;
