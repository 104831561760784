import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const SignUpCheckmark: FC = () => {
  return (
    <SvgIcon>
      <defs>
        <clipPath id="clip-SignUp">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
      <g id="SignUp" clipPath="url(#clip-SignUp)">
        <path
          id="Pfad_4077"
          data-name="Pfad 4077"
          d="M20.32-19.854v4.589A4,4,0,0,1,19.1-12.328a4,4,0,0,1-2.937,1.219H4.156a4,4,0,0,1-2.937-1.219A4,4,0,0,1,0-15.265V-27.272a4,4,0,0,1,1.219-2.937,4,4,0,0,1,2.937-1.219H16.164a3.97,3.97,0,0,1,1.689.361.437.437,0,0,1,.26.332.449.449,0,0,1-.13.419l-.707.707a.456.456,0,0,1-.332.144.458.458,0,0,1-.13-.029,2.567,2.567,0,0,0-.649-.087H4.156a2.223,2.223,0,0,0-1.631.678,2.223,2.223,0,0,0-.678,1.631v12.007a2.223,2.223,0,0,0,.678,1.631,2.223,2.223,0,0,0,1.631.678H16.164a2.223,2.223,0,0,0,1.631-.678,2.223,2.223,0,0,0,.678-1.631v-3.666a.432.432,0,0,1,.13-.317l.924-.924a.456.456,0,0,1,.332-.144.384.384,0,0,1,.173.043A.419.419,0,0,1,20.32-19.854Zm3.334-7.057L11.906-15.164a1.124,1.124,0,0,1-.823.346,1.124,1.124,0,0,1-.823-.346L4.055-21.37a1.124,1.124,0,0,1-.346-.823,1.124,1.124,0,0,1,.346-.823L5.643-24.6a1.124,1.124,0,0,1,.823-.346,1.124,1.124,0,0,1,.823.346l3.8,3.8,9.337-9.337a1.124,1.124,0,0,1,.823-.346,1.124,1.124,0,0,1,.823.346l1.587,1.587a1.124,1.124,0,0,1,.346.823A1.124,1.124,0,0,1,23.654-26.911Z"
          transform="translate(0 33.269)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default SignUpCheckmark;
