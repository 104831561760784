import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_CHAT_SETTINGS } from '../../../../graphql/VXModels/queries';
import { Query } from '../../../../graphql/VXModels/types';
import { QueryResult } from '@apollo/react-common';

const useGetChatSettings = (): Pick<QueryResult<Query>, 'data' | 'loading' | 'error'> => {
  const { data, loading, error } = useQuery<Query>(QUERY_MODEL_CHAT_SETTINGS, {
    fetchPolicy: 'network-only',
  });
  return { data, loading, error };
};

export default useGetChatSettings;
