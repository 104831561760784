import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Query } from '@apollo/react-components';

import { stripSlash } from '../../../util/urlHelper';
import { ProtectedRoute, Spinner } from '../../../components';
import AlbumViewProfileFL from '../../../components/MediaManagement/ExplorerPicture/AlbumViewProfileFL/AlbumViewProfileFL';

import { QUERY_PHOTO_ALBUMS_BY_TYPE } from '../../../graphql/VXModels/queries';

import { APP_BASE_PATH } from '../../../util/env';
import Common from './Common/Common';
import AutoMails from './AutoMails/AutoMails';
import Settings from './Settings/Settings';
import Profilepic from './Profilepic/Profilepic';

const DEFAULT_ROUTE = `/common`;

const FL = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <Switch>

      <Redirect exact to={`${APP_BASE_PATH}/onlineprofile/overview`} />

      <ProtectedRoute exact path={`${path}/common`} component={Common} />
      <ProtectedRoute exact path={`${path}/automails`} component={AutoMails} />
      <ProtectedRoute exact path={`${path}/settings`} component={Settings} />
      <ProtectedRoute
        exact
        path={`${path}/profilepic`}
        component={() => (
          <Query query={QUERY_PHOTO_ALBUMS_BY_TYPE} variables={{ type: 'flirtlife' }}>
            {({ loading, data, error, refetch }) => {
              if (loading) return <Spinner />;
              if (error) return console.error(error);
              const albums = (data.model.photoAlbums && data.model.photoAlbums.albums) || null;

              return (
                <Profilepic>
                  <AlbumViewProfileFL
                    refetchAlbum={refetch}
                    album={albums[0]}
                    isLoading={loading}
                  />
                </Profilepic>
              );
            }}
          </Query>
        )}
      />
      <Redirect exact to={`${path}${DEFAULT_ROUTE}`} />
    </Switch>
  );
};

export default FL;
